<script>
import "leaflet/dist/leaflet.css";
import dayjs from 'dayjs'
import gql from 'graphql-tag';
import * as L from 'leaflet'
// import 'leaflet-easyprint';
import { mapState } from 'vuex'

  export default {
    name: "mapa",
    components: {
      FilterMap: () => import('./FilterMap.vue')
    },

    data: () => ({
        center: [37,7749, -122,4194],
        filePath: '',
        layers: '',
        allLayers: '',
        mapa: '',
        layer: '',
        info: '',
        geoJson: '',
        legend: '',
        farmID: '',
        module: 'Todos',
        limitDate: '',
        measureDate: '',
        sentinelHubLayer: '',
        currentLabelVisibility: true,
        lastZoom: 15,
        tooltipThreshold: 15,
        rangeMS: [],
        rangeFDN: [],
        rangePB: [],
        rangeEM: [],
        changingFarm: false,
        moduleCodes: {},
        date: null,
        oldDate: null,
    }),

    computed: {
      ...mapState('dashboard', ['filter'])
    },

    watch:{
      imediate: true,
      async filter() {
        if (this.farmID && this.filter.farmId && this.farmID !== this.filter.farmId) {
          if(this.geoJson){
            this.mapa.removeLayer(this.geoJson)
          }
          this.changingFarm = true
          await this.changeFarm()
          this.changingFarm = false
        }
        if (this.filter.date) {
          this.date = this.filter.date
        }
        if (this.filter.date && this.filter.date !== this.oldDate && !this.changingFarm) {
          this.oldDate = this.filter.date
          if(this.oldDate.includes('.')) {
            this.oldDate = dayjs(this.oldDate).format('DD-MM-YYYY')
          }
          this.date = this.filter.date
          let date = dayjs(this.date)
          let limitDate = dayjs(date).subtract(6,'month').format('YYYY-MM-DD')
          this.limitDate = limitDate
          let measureDate = dayjs(date).format('YYYY-MM-DD')
          this.measureDate = measureDate
          await this.changeMeasureData()
          await this.changeMapDate()
        }
        if (this.filter.type) {
          this.type = this.filter.type
          if (this.layers) {
            await this.showMeasureInfo()
          }
        }
        if (this.filter.moduleId && this.module && this.module !== this.filter.moduleId ) {
          this.module = this.filter.moduleId
          await this.changeLayer()
          await this.showMeasureInfo()
        }
        if (this.filter.label !== this.currentLabelVisibility) {
          await this.alterLabelVisibility()
          this.currentLabelVisibility = !this.currentLabelVisibility
        }
      }
    },

    mounted() {
        this.setupLeafletMap();
    },

    methods: {
      setupLeafletMap: async function () {
        var geoJson
        this.mapa = L.map('mapa', {
          minZoom: 14,
          zoomControl: false
        }).setView([0, 0], 15);
        var mapa = this.mapa
        var legend = L.control({position: 'bottomright'});
        this.legend = legend
        var info = L.control({position: 'bottomleft'});
        this.info = info
        let farmID = localStorage.getItem('lastFarm')
        this.farmID = farmID
        
        const geoData = await this.$apollo.query({
          query: gql`
            query farm_map_data($farm_id: uuid! ) {
              farm_map_data(
                where: {  
                  farm_id: {_eq: $farm_id},
                  deleted_at: {_is_null: true}
                }, order_by: { created_at: desc },
                   limit: 1,
              ) {
                geo_data
                }
            }
          `,
          variables: {
            farm_id: farmID,
          },
          fetchPolicy: 'no-cache',
        });

        const farmModules = await this.$apollo.query({
          query: gql`
            query modules($farm_id: uuid! ) {
              modules(
                where: {  
                  farm_id: {_eq: $farm_id}
                }, order_by: { created_at: desc },
              ) {
                id,
                name,
                code,
                module_color
                }
            }
          `,
          variables: {
            farm_id: farmID,
          },
          fetchPolicy: 'no-cache',
        });
        let paddocksArray = []
        let paddocksArea = {}
        if(farmModules && farmModules.data && farmModules.data.modules) {
          let modules = farmModules.data.modules
          for(let index = 0; index < modules.length; index++) {
            let moduleID = modules[index].id
            if (modules[index].module_color) {
              this.moduleCodes[modules[index].code] = modules[index].module_color
            } else {
              let h
              if (index % 2 === 0) {
                h = 300
              } else {
                h = 240
              }
              let s = Math.floor(Math.random() * 100);
              let l = Math.floor(Math.random() * 100);
              if (l < 30) {
                l = 30
              }
              let color = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
              this.moduleCodes[modules[index].code] = color
            }
            let paddockArea = await this.$apollo.query({
              query: gql`
                query paddocks($module_id: uuid! ) {
                  paddocks(
                    where: {  
                      module_id: {_eq: $module_id}
                    }, order_by: { created_at: asc },
                  ) {
                    id,
                    name,
                    area,
                    code
                    }
                }
              `,
              variables: {
                module_id: moduleID,
              },
              fetchPolicy: 'no-cache',
            });
            if (paddockArea && paddockArea.data && paddockArea.data.paddocks) {
              paddocksArray = [...paddocksArray, ...paddockArea.data.paddocks]
            }
          }
          for(let index = 0; index < paddocksArray.length; index++) {
            let paddock = paddocksArray[index]
            paddocksArea[paddock.code] = paddock
          }
        }
        var colors = this.moduleCodes
        var infoUpdate = true
        function highlightFeature(e) {
          var layer = e.target;
          let code = layer.feature.properties.Name.split('_')
          layer.setStyle({
            weight: 5,
            color: colors[code[0]],
            dashArray: '',
            fillOpacity: 0.7
          });
          if (infoUpdate) {
            info.update(layer.feature.properties);
          }
          layer.bringToFront();
         }
         function resetHighlight(e) {
          geoJson.resetStyle(e.target);
          if (infoUpdate) {
            info.update();
          }
        }
        function lockInfo(e) {
          infoUpdate = false
          var layer = e.target;
          // mapa.fitBounds(e.target.getBounds());
          info.update(layer.feature.properties);
        }
        function onEachFeature(feature, layer) {
          layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: lockInfo
          });
        }
        function style(layer) {
          let code = layer.properties.Name.split('_')
          return {
              fillColor:  colors[code[0]] || '#FFEDA0',
              weight: 2,
              opacity: 1,
              color: colors[code[0]],
              fillOpacity: 0
          };
        }

        info.onAdd = function () {
            this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
            L.DomUtil.addClass(this._div, 'info')
            this.update();
            return this._div;
        };

        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
            this._div.innerHTML = (props?
              '<b>Piquete: </b> '+ props.Name +'<br />'+' <b> Área: </b>'+ (props.area? props.area.toLocaleString() + ' ha': '') + '<br/>'
              + '<b> Matéria Seca no Piquete : </b> ' + (props.visibleMS? props.visibleMS.toLocaleString() : '') + ' kg' + '<br/>'
              + '<b> Matéria Seca por área : </b> ' + (props.ms? props.ms.toLocaleString()  : '') + ' kg/ha' +'<br/>'
              + '<b> Proteína : </b> ' + (props.pb? props.pb.toLocaleString() : '') + '%' +'<br/>'
              + '<b> Fibra : </b> ' + (props.fdn? props.fdn.toLocaleString() : '') + '%' + '<br/>'
              + '<b> Energia : </b> ' + (props.em? props.em.toLocaleString() : '') + ' kcal/kg' + '<br/>'
              : 'Passe o mouse sobre um piquete para visualizar');
          };


        info.addTo(mapa);
        let date = dayjs(new Date())
        let limitDate = dayjs(date).subtract(6,'month').format('YYYY-MM-DD')
        this.limitDate = limitDate
        let measureDate = dayjs(date).format('YYYY-MM-DD')
        this.measureDate = measureDate
        let baseUrl = "https://services.sentinel-hub.com/ogc/wms/84623a0a-c9b3-4489-bda6-996e0ebeda71";
        let sentinelHub = L.tileLayer.wms(baseUrl, {
            tileSize: 512,
            attribution: '&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>',
                    urlProcessingApi:"https://services.sentinel-hub.com/ogc/wms/1d4de4a3-2f50-493c-abd8-861dec3ae6b2",
                maxcc:100, 
                minZoom:14, 
                maxZoom:19, 
                gain:1.8,
                gama:1.2,
                preset:"NATURAL-COLOR", 
                layers:"NATURAL-COLOR", 
                time: limitDate + '/'+ measureDate, 
        }).addTo(mapa);
        this.sentinelHubLayer = sentinelHub
        let osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })

        let baseMaps = {
          'OpenStreetMap': osm
        };
        let overlayMaps = {
          'Sentinel Hub WMS': sentinelHub
        }
        L.control.layers(baseMaps, overlayMaps).addTo(mapa);
        /* L.easyPrint({
          title: 'Baixar como...',
          position: 'bottomright',
          sizeModes: ['A4Landscape'],
          defaultSizeTitles: {A4Landscape: 'Imagem'},
          hideClasses: ['leaflet-control-zoom', 'leaflet-control-layers', 'leaflet-control-attribution leaflet-control', 'leaflet-control-easyPrint-button-export'],
          exportOnly: true,
          hideControlContainer: false,
        }).addTo(mapa); */
        var logo = L.control({position: "topleft"});
          logo.onAdd = function() {
              var div = L.DomUtil.create("div", "img");
              this.img
              div.innerHTML = `<img src="img/logo.a6571bfe.png" width="20%"/>`;
              return div;
          }
          logo.addTo(mapa);
          L.control.zoom({
            position: 'topleft'
          }).addTo(mapa);

        if (geoData && geoData.data && geoData.data.farm_map_data.length > 0) {
          this.layers = JSON.parse(geoData.data.farm_map_data[0].geo_data)
          this.allLayers = this.layers.features
          for (let index = 0; index< this.layers.features.length; index++) {
            let layer = this.layers.features[index].properties
            if (layer.name) {
              layer.Name = layer.name
            }
            if (layer.Name === paddocksArea[layer.Name].code) {
              this.layers.features[index].properties.area = paddocksArea[layer.Name].area
              this.layers.features[index].properties.id = paddocksArea[layer.Name].id
            }
          }
          geoJson = L.geoJson(this.layers, {
            style: style,
            onEachFeature: onEachFeature,
          }).addTo(this.mapa)
          this.geoJson = geoJson
          let centerPoint = await this.calcCenterPoint()
          this.mapa.panTo(new L.LatLng(centerPoint[1],centerPoint[0]))
          this.mapa.setMaxBounds(this.mapa.getBounds())
        }
        if (this.type && this.type !== 'Imagem Satélite' && this.date) {
          await this.changeMeasureData()
          await this.showMeasureInfo()
        }
      },

      calcCenterPoint() {
        let centerPoint = []
          let minX = 0
          let maxX = 0
          let minY = 0
          let maxY = 0
          if (this.layers.length > 1) {
            let point = this.layers[0].features.geometry.coordinates[0][0]
            minX = point[0]
            maxX = point[0]
            minY = point[1]
            maxY = point[1]
            for (let index = 1; index < this.layers.length; index++) {
              point = this.layers[index].features.geometry.coordinates[0][0]
              if (minX > point[0]) {
                minX = point[0]
              }
              if(maxX < point[0]) {
                maxX = point[0]
              }
              if (minY > point[1]) {
                minY = point[1]
              }
              if(maxY < point[1]) {
                maxY = point[1]
              }
            }
            centerPoint.push(minX + ((maxX - minX)/2))
            centerPoint.push(minY + ((maxY - minY)/2))
          } else {
            let point = this.layers.features[0].geometry.coordinates[0][0]
            minX = point[0]
            maxX = point[0]
            minY = point[1]
            maxY = point[1]
            let layers = this.layers.features
            for (let index = 1; index < layers.length; index++) {
              point = layers[index].geometry.coordinates[0][0]
              if (minX > point[0]) {
                minX = point[0]
              }
              if(maxX < point[0]) {
                maxX = point[0]
              }
              if (minY > point[1]) {
                minY = point[1]
              }
              if(maxY < point[1]) {
                maxY = point[1]
              }
            }
            centerPoint.push(minX + ((maxX - minX)/2))
            centerPoint.push(minY + ((maxY - minY)/2))
          }
          return centerPoint
      },

      async showMeasureInfo() {
        var mapa = this.mapa
        let labelVisibility = this.currentLabelVisibility
        var geoJson = this.geoJson
        var info = this.info
        var legend = this.legend
        var type = this.type
        var colors = this.moduleCodes
        var module = this.module
        if (this.type) {
          if (geoJson) { 
            mapa.removeLayer(geoJson)
          }
          info.remove(mapa)
          legend.remove(mapa)
        }
        var gradeModule = []
        if (this.module !== 'Todos') {
          let valueDivided
          if(this.type === 'Matéria seca') {
            valueDivided = this.rangeMS[0] / 7
            gradeModule[0] = 0
            for (let index = 1; index < 6; index++) {
              gradeModule[index] = Math.round((valueDivided * index)/50)*50
            }
            gradeModule.push(Math.floor(this.rangeMS[0]/50)*50)
          } else if (this.type === 'Fibra') {
            if (this.rangeFDN[0] > 0) {
              valueDivided = (this.rangeFDN[0] - this.rangeFDN[1]) / 7
              gradeModule[0] = this.rangeFDN[1]
              for (let index = 1; index < 6; index++) {
                gradeModule[index] = parseFloat((valueDivided * index + this.rangeFDN[1]).toFixed(0))
              }
              gradeModule.push(this.rangeFDN[0])
            }
          } else if (this.type === 'Proteína') {
            if (this.rangePB[0] > 0) {
              valueDivided = (this.rangePB[0] - this.rangePB[1]) / 7
              gradeModule[0] = this.rangePB[1]
              for (let index = 1; index < 6; index++) {
                gradeModule[index] = parseFloat((valueDivided * index + this.rangePB[1]).toFixed(1))
              }
              gradeModule.push(this.rangePB[0])
            }
          }
          else if (this.type === 'Energia') {
            if (this.rangeEM[0] > 0) {
              valueDivided = (this.rangeEM[0] - this.rangeEM[1]) / 7
              gradeModule[0] = this.rangeEM[1]
              for (let index = 1; index < 6; index++) {
                gradeModule[index] = parseFloat((valueDivided * index + this.rangeEM[1]).toFixed(0))
              }
              gradeModule.push(this.rangeEM[0])
            }
          }
        }
        function getColor(value) {
            if(type === 'Matéria seca') {
              return value >= gradeModule[gradeModule.length-1] ? '#008000' :
                  value > gradeModule[gradeModule.length-2] ? '#71B200' :
                  value > gradeModule[gradeModule.length-3] ? '#4ced0c' :
                  value > gradeModule[gradeModule.length-4]  ? '#eded05' :
                  value > gradeModule[gradeModule.length-5] ? '#f58f14' :
                  value > gradeModule[gradeModule.length-6]  ? '#f51b1b' :
                  value > 0  ? '#8b0000' :
                              '#FFFFFF';
                              
            } else if (type === 'Fibra') {
              return value >= gradeModule[gradeModule.length-1] ? '#5c2002' :
                value > gradeModule[gradeModule.length-2] ? '#CC3300' :
                value > gradeModule[gradeModule.length-3] ? '#c4912b' :
                value > gradeModule[gradeModule.length-4] ? '#FFC000' :
                value > gradeModule[gradeModule.length-5] ? '#70AD47' :
                value > gradeModule[gradeModule.length-6] ? '#548235' :
                value > 0  ? '#333300' :
                            '#FFFFFF';
            } else if (type === 'Energia') {
              return value >= gradeModule[gradeModule.length-1] ? '#0000FF' :
                value > gradeModule[gradeModule.length-2]  ? '#5555AA' :
                value > gradeModule[gradeModule.length-3]  ? '#AAAA55' :
                value > gradeModule[gradeModule.length-4]  ? '#FFFF00' :
                value > gradeModule[gradeModule.length-5]  ? '#FFAA00' :
                value > gradeModule[gradeModule.length-6]  ? '#FF5500' :
                value > 0  ? '#FF0000' :
                            '#FFFFFF';
            } else if (type === 'Proteína') {
              return value >= gradeModule[gradeModule.length-1] ? '#003300' :
                value > gradeModule[gradeModule.length-2]  ? '#008000' :
                value > gradeModule[gradeModule.length-3]  ? '#2be80e' :
                value > gradeModule[gradeModule.length-4]  ? '#92D050' :
                value > gradeModule[gradeModule.length-5]  ? '#FFC000' :
                value > gradeModule[gradeModule.length-6]  ? '#BF9000' :
                value > 0  ? '#CC6600' :
                            '#FFFFFF';
            } else {
              return false
            }
        }
        function style(feature) {
          let value
          if(type === 'Matéria seca') {
            value = feature.properties.ms
          } else if (type === 'Fibra') {
            value = feature.properties.fdn  
          } else if (type === 'Energia') {
            value = feature.properties.em
          } else if (type === 'Proteína') {
            value = feature.properties.pb
          }
          let code = feature.properties.Name.split('_')
          return {
              fillColor: type !== 'Imagem Satélite'? (getColor(value) || colors[code[0]]) : '#FFFFFF',
              weight: 2,
              opacity: 1,
              color: colors[code[0]],
              fillOpacity: type !== 'Imagem Satélite'? 0.8 : 0
          };
        }
        if (this.type && this.type !== 'Imagem Satélite') {
          info.onAdd = function () {
              this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
              this.update();
              return this._div;
          };

          // method that we will use to update the control based on feature properties passed
          info.update = function (props) {
            this._div.innerHTML = (props?
              '<b>Piquete: </b> '+ props.Name +'<br />'+' <b> Área: </b>'+ (props.area? props.area.toLocaleString() + ' ha': '') + '<br/>'
              + '<b> Matéria Seca no Piquete : </b> ' + (props.visibleMS? props.visibleMS.toLocaleString() : '') + ' kg' + '<br/>'
              + '<b> Matéria Seca por área : </b> ' + (props.ms? props.ms.toLocaleString()  : '') + ' kg/ha' +'<br/>'
              + '<b> Proteína : </b> ' + (props.pb? props.pb.toLocaleString() : '') + '%' +'<br/>'
              + '<b> Fibra : </b> ' + (props.fdn? props.fdn.toLocaleString() : '') + '%' + '<br/>'
              + '<b> Energia : </b> ' + (props.em? props.em.toLocaleString() : '') + ' kcal/kg' + '<br/>'
              : 'Passe o mouse sobre um piquete para visualizar');
          };
          if (type !== ' ') {
            const layers = this.allLayers
            legend.onAdd = function () {
              var grades
              if (module == 'Todos') {
                if(type === 'Matéria seca') {
                  let greaterMs = 0
                  let lesserMs = null
                  for (let index = 0; index < layers.length; index++ ) {
                    let layer = layers[index]
                    if (layer.properties.ms !== undefined && greaterMs < parseFloat(layer.properties.ms)) {
                      greaterMs = layer.properties.ms
                    } 
                    if (layer.properties.ms !== undefined && (lesserMs === null || lesserMs > parseFloat(layer.properties.ms))) {
                      lesserMs = layer.properties.ms
                    }
                  }
                  const valueDivided = greaterMs / 7
                
                  gradeModule[0] = 0
                  for (let index = 1; index < (6); index++) {
                    gradeModule[index] = Math.round((valueDivided * index)/50)*50
                  }
                  gradeModule.push(Math.floor(greaterMs/50)*50)
                  grades = gradeModule
                } else if (type === 'Fibra') {
                  // grades = [0, 20, 30, 40, 50, 60, 70, 80, 90]
                  let greaterFDN = 0
                  let lesserFDN = null
                  for (let index = 0; index < layers.length; index++ ) {
                    let layer = layers[index]
                    if (greaterFDN < parseFloat(layer.properties.fdn)) {
                      greaterFDN = layer.properties.fdn
                    } 
                    if (lesserFDN === null || lesserFDN > parseFloat(layer.properties.fdn)) {
                      lesserFDN = layer.properties.fdn
                    }
                  }
                  if (greaterFDN > 0) {
                    const valueDivided = (greaterFDN - lesserFDN) / 7
                    gradeModule[0] = lesserFDN
                    for (let index = 1; index < (6); index++) {
                      gradeModule[index] = parseFloat((valueDivided * index + lesserFDN).toFixed(0))
                    }
                    gradeModule.push(greaterFDN)
                    grades = gradeModule
                  }
                } else if (type === 'Energia') {
                  // grades = [0, 500, 1000, 1500, 2000, 2500, 3000]

                  let greaterEM = 0
                  let lesserEM = null
                  for (let index = 0; index < layers.length; index++ ) {
                    let layer = layers[index]
                    if (greaterEM < parseFloat(layer.properties.em)) {
                      greaterEM = layer.properties.em
                    } 
                    if (lesserEM === null || lesserEM > parseFloat(layer.properties.em)) {
                      lesserEM = layer.properties.em
                    }
                  }
                  if (greaterEM > 0) {
                    const valueDivided = (greaterEM - lesserEM) / 7
                    gradeModule[0] = lesserEM
                    for (let index = 1; index < (6); index++) {
                      gradeModule[index] = parseFloat((valueDivided * index + lesserEM).toFixed(0))
                    }
                    gradeModule.push(greaterEM)
                    grades = gradeModule
                  }

                } else if (type === 'Proteína') {
                  // grades = [0, 2, 4, 6, 8, 10, 11]
                  let greaterPB = 0
                  let lesserPB = null
                  for (let index = 0; index < layers.length; index++ ) {
                    let layer = layers[index]
                    if (greaterPB < parseFloat(layer.properties.pb)) {
                      greaterPB = layer.properties.pb
                    } 
                    if (lesserPB === null || lesserPB > parseFloat(layer.properties.pb)) {
                      lesserPB = layer.properties.pb
                    }
                  }
                  if (greaterPB > 0) {
                    const valueDivided = (greaterPB - lesserPB) / 7
                    gradeModule[0] = lesserPB
                    for (let index = 1; index < (6); index++) {
                      gradeModule[index] = parseFloat((valueDivided * index + lesserPB ).toFixed(1))
                    }
                    gradeModule.push(greaterPB)
                    grades = gradeModule
                  }
                }
              } else {
                  grades = gradeModule
              }
              var div = L.DomUtil.create('div', 'info legend')
              // loop through our density intervals and generate a label with a colored square for each interval
              div.innerHTML += type? type === 'Matéria seca'? type +  ' (kg/ha)' + '<br>' : type === 'Energia'? type + ' (kcal/kg)' + '<br>' : type + ' (%)' + '<br>'  : '' 
              div.innerHTML +=
                    '<i style="background:' + '#FFFFFF' + '; box-shadow: 0 0 4px rgba(0,0,0,0.2);"></i> ' + 'sem dado'  + '<br>';
              if (grades && grades.length > 0) {
                for (var i = 0; i < grades.length; i++) {
                  div.innerHTML +=
                      '<i style="background:' + getColor(grades[i] + 0.1) + '"></i> ' +
                      grades[i].toLocaleString() + (grades[i + 1] ? '&ndash;' + grades[i + 1].toLocaleString() + '<br>' : '+');
                }
              }
              return div;
            };
            legend.addTo(mapa);
          }
          info.addTo(mapa);
          
        }
        var infoUpdate = true
        function highlightFeature(e) {
          var layer = e.target;
          let code = layer.feature.properties.Name.split('_')
          layer.setStyle({
                weight: 5,
                color: colors[code[0]],
                dashArray: '',
                fillOpacity: 0.7
          });
          layer.bringToFront();
          if(infoUpdate) {
            info.update(layer.feature.properties);
          }
        }
         function resetHighlight(e) {
          geoJson.resetStyle(e.target);
          if(infoUpdate) {
            info.update();
          }
        }
        function lockInfo(e) {
          infoUpdate = false
          var layer = e.target;
          // mapa.fitBounds(e.target.getBounds());
          info.update(layer.feature.properties);
        }
        function textColor(color) {
          let r
          let g
          let b
          let hsp
          color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
          hsp = Math.sqrt( 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
          if (hsp>127.5) {
            return 'light';
          } 
          else {
            return 'dark';
          }
        }
        function onEachFeature(feature, layer) {
          if (layer && layer.options && layer.options.fillColor) {
            const brightness = textColor(layer.options.fillColor)
            if (brightness === 'light') {
              layer.bindTooltip(layer.feature.properties.Name, {className: 'tooltip-dark',permanent: labelVisibility, direction:"center"}).openTooltip()
            } else {
              layer.bindTooltip(layer.feature.properties.Name, {className: 'tooltip-light',permanent: labelVisibility, direction:"center"}).openTooltip()
            }
          }
          layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: lockInfo
          });
        }
        let measures = this.measures
        if (this.layers && this.layers.features) {
          for (let index = 0; index< this.layers.features.length; index++) {
            let layerId = this.layers.features[index].properties.id
            if (measures && measures[layerId]) {
              if (measures[layerId].visible_area > 0) {
                this.layers.features[index].properties.ms = parseFloat(((measures[layerId].visible_ms) / (measures[layerId].visible_area)).toFixed(0))
              } else {
                this.layers.features[index].properties.ms = 0
              }
              this.layers.features[index].properties.visibleMS = (measures[layerId].visible_ms)
              this.layers.features[index].properties.fdn = measures[layerId].fdn
              this.layers.features[index].properties.em = measures[layerId].em
              this.layers.features[index].properties.pb = measures[layerId].pb
            }
          }
        
          /*let lastZoom = this.lastZoom
          let tooltipThreshold = this.tooltipThreshold
          mapa.on('zoomend', function() {
            var zoom = mapa.getZoom();
            if (zoom < tooltipThreshold && (lastZoom >= tooltipThreshold)) {
                mapa.eachLayer(function(l) {
                    if (l.getTooltip()) {
                        var tooltip = l.getTooltip();
                        l.unbindTooltip().bindTooltip(tooltip, {
                            permanent: false
                        })
                    }
                })
            } else if (zoom >= tooltipThreshold && (lastZoom < tooltipThreshold)) {
                mapa.eachLayer(function(l) {
                    if (l.getTooltip()) {
                        var tooltip = l.getTooltip();
                        l.unbindTooltip().bindTooltip(tooltip, {
                            permanent: true
                        })
                    }
                });
            }
            lastZoom = zoom;
          })
          this.lastZoom = lastZoom */

        }
        geoJson = L.geoJson(this.layers, {
          style: style,
          onEachFeature: onEachFeature,
        }).addTo(this.mapa)
        this.geoJson = geoJson
      },

      async changeMeasureData() {
        var info = this.info
        var mapa = this.mapa
        if (this.date) {
          let date = []
          if (typeof this.date === 'string') {
            date = this.date.split('-')
            if (!(date[0].length > 2)) {
              this.date = dayjs(date[2]+date[1]+date[0])
            }
          }
          const measureData = await this.$apollo.query({
            query: gql`
              query satellites_data($farm_id: uuid!, $captured_at: timestamptz ) {
                satellites_data(
                  where: {  
                    farm_id: {_eq: $farm_id},
                    captured_at: {_eq: $captured_at},
                    type: {_eq: OPTICO},
                  }, order_by: { created_at: desc },
                ) {
                  paddock_id
                  visible_ms
                  visible_area
                  pb
                  fdn
                  em
                  }
              }
            `,
            variables: {
              farm_id: localStorage.getItem('lastFarm'),
              captured_at: this.date
            },
            fetchPolicy: 'no-cache',
          });
          if (measureData && measureData.data && measureData.data.satellites_data && measureData.data.satellites_data[0]) {
            let measures = measureData.data.satellites_data
            let data = {}
            for (let index = 0; index < measures.length; index++) {
              data[measures[index].paddock_id] = measures[index]
            }
            this.measures = data
          }
          info.remove(mapa)
          if (this.layers && this.layers.features) {
            for (let index = 0; index< this.layers.features.length; index++) {
              let layerId = this.layers.features[index].properties.id
              if (this.measures && this.measures[layerId] && this.measures[layerId].visible_ms) {
                if (this.measures[layerId].visible_area > 0) {
                this.layers.features[index].properties.ms = parseFloat(((this.measures[layerId].visible_ms) / (this.measures[layerId].visible_area)).toFixed(0))
              } else {
                this.layers.features[index].properties.ms = 0
              }
              }
            }
          }
          info.onAdd = function () {
            this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
            L.DomUtil.addClass(this._div, 'info')
            this.update();
            return this._div;
          };
          // method that we will use to update the control based on feature properties passed
          info.update = function (props) {
            this._div.innerHTML = (props?
              '<b>Piquete: </b> '+ props.Name +'<br />'+' <b> Área: </b>'+ (props.area? props.area.toLocaleString() + ' ha': '') + '<br/>'
              + '<b> Matéria Seca no Piquete : </b> ' + (props.visibleMS? props.visibleMS.toLocaleString() : '') + ' kg' + '<br/>'
              + '<b> Matéria Seca por área : </b> ' + (props.ms? props.ms.toLocaleString()  : '') + ' kg/ha' +'<br/>'
              + '<b> Proteína : </b> ' + (props.pb? props.pb.toLocaleString() : '') + '%' +'<br/>'
              + '<b> Fibra : </b> ' + (props.fdn? props.fdn.toLocaleString() : '') + '%' + '<br/>'
              + '<b> Energia : </b> ' + (props.em? props.em.toLocaleString() : '') + ' kcal/kg' + '<br/>'
              : 'Passe o mouse sobre um piquete para visualizar');
          };
          info.addTo(mapa);
        }
      },

      async changeMapDate() {
        if (this.sentinelHubLayer && this.sentinelHubLayer.options) {
          this.sentinelHubLayer.options.time = this.limitDate  + '/' + this.measureDate
          this.sentinelHubLayer.wmsParams.time = this.limitDate  + '/' + this.measureDate
          this.sentinelHubLayer.setParams({}, false);
        }
      },

      async changeFarm() {
        const farmID = this.filter.farmId
        var mapa = this.mapa
        var info = this.info
        var geoJson = this.geoJson
        if (farmID !== this.farmID) {
          if (geoJson) {
            mapa.removeLayer(geoJson)
          }
          info.remove(mapa)
          this.farmID = farmID
        }
        const geoData = await this.$apollo.query({
          query: gql`
            query farm_map_data($farm_id: uuid! ) {
              farm_map_data(
                where: {  
                  farm_id: {_eq: $farm_id},
                  deleted_at: {_is_null: true}
                }, order_by: { created_at: asc },
                   limit: 1,
              ) {
                geo_data
                }
            }
          `,
          variables: {
            farm_id: farmID,
          },
          fetchPolicy: 'no-cache',
        });

        const farmModules = await this.$apollo.query({
          query: gql`
            query modules($farm_id: uuid! ) {
              modules(
                where: {  
                  farm_id: {_eq: $farm_id}
                }, order_by: { created_at: desc },
              ) {
                id,
                name,
                code,
                module_color
                }
            }
          `,
          variables: {
            farm_id: farmID,
          },
          fetchPolicy: 'no-cache',
        });
        let paddocksArray = []
        let paddocksArea = {}
        if(farmModules && farmModules.data && farmModules.data.modules) {
          let modules = farmModules.data.modules
          for(let index = 0; index < modules.length; index++) {
            let moduleID = modules[index].id
            if (modules[index].module_color) {
              this.moduleCodes[modules[index].code] = modules[index].module_color
            } else {
              let h
              if (index % 2 === 0) {
                h = 300
              } else {
                h = 240
              }
              let s = Math.floor(Math.random() * 100);
              let l = Math.floor(Math.random() * 100);
              if (l < 30) {
                l = 30
              }
              let color = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
              this.moduleCodes[modules[index].code] = color
            }
            // this.moduleCodes[modules[index].code] = '#' + Math.floor(Math.random()*16777215).toString(16);
            let paddockArea = await this.$apollo.query({
              query: gql`
                query paddocks($module_id: uuid! ) {
                  paddocks(
                    where: {  
                      module_id: {_eq: $module_id}
                    }, order_by: { created_at: asc },
                  ) {
                    id,
                    name,
                    area,
                    code
                    }
                }
              `,
              variables: {
                module_id: moduleID,
              },
              fetchPolicy: 'no-cache',
            });
            if (paddockArea && paddockArea.data && paddockArea.data.paddocks) {
              paddocksArray = [...paddocksArray, ...paddockArea.data.paddocks]
            }
          }
          for(let index = 0; index < paddocksArray.length; index++) {
            let paddock = paddocksArray[index]
            paddocksArea[paddock.code] = paddock
          }
        }
        var colors = this.moduleCodes
        var infoUpdate = true
        function highlightFeature(e) {
          var layer = e.target;
          let code = layer.feature.properties.Name.split('_')
          layer.setStyle({
                  weight: 5,
                  color: colors[code[0]],
                  dashArray: '',
                  fillOpacity: 0.7
              });
            if(infoUpdate) {
              info.update(layer.feature.properties);
            }
          layer.bringToFront();
         }
         function resetHighlight(e) {
          geoJson.resetStyle(e.target);
          if (infoUpdate) {
            info.update();
          }
        }
        function lockInfo(e) {
          infoUpdate = false
          var layer = e.target;
          // mapa.fitBounds(e.target.getBounds());
          info.update(layer.feature.properties);
        }
        function onEachFeature(feature, layer) {
          layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: lockInfo
          });
        }
        function style(layer) {
          let code = layer.properties.Name.split('_')
          return {
              fillColor:  colors[code[0]] || '#FFEDA0',
              weight: 2,
              opacity: 1,
              color: colors[code[0]],
              fillOpacity: 0
          };
        }
        info.onAdd = function () {
            this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
            L.DomUtil.addClass(this._div, 'info')
            this.update();
            return this._div;
        };

        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
          this._div.innerHTML = '<h4>Piquete</h4>' +  (props?
              ' <b> área do piquete: </b>'+ (props.area? props.area + ' ha': '')   +'<br />' + '<b>Nome do piquete : </b> '+ props.Name + '<br/>'
              : 'Passe o mouse sobre um piquete para visualizar');
        };


        info.addTo(mapa);

        if (geoData && geoData.data && geoData.data.farm_map_data.length > 0) {
          this.layers = JSON.parse(geoData.data.farm_map_data[0].geo_data)
          this.allLayers = this.layers.features
          for (let index = 0; index< this.layers.features.length; index++) {
            let layer = this.layers.features[index].properties
            if (layer.name) {
              layer.Name = layer.name
            }
            if (layer.Name === paddocksArea[layer.Name].code) {
              this.layers.features[index].properties.area = paddocksArea[layer.Name].area
              this.layers.features[index].properties.id = paddocksArea[layer.Name].id
            }
          }
          geoJson = L.geoJson(this.layers, {
            style: style,
            onEachFeature: onEachFeature,
          }).addTo(this.mapa)
          this.geoJson = geoJson
          let centerPoint = await this.calcCenterPoint()
          this.mapa.setMaxBounds()
          this.mapa.panTo(new L.LatLng(centerPoint[1],centerPoint[0]))
          this.mapa.setMaxBounds(this.mapa.getBounds())
        }
      },

      async changeLayer() {

        if (this.module !== 'Todos') {
          const module = await this.$apollo.query({
            query: gql`
              query modules($id: uuid! ) {
                modules(
                  where: {  
                    id: {_eq: $id},
                    deleted_at: {_is_null: true}
                  }, order_by: { created_at: desc },
                    limit: 1,
                ) {
                  code
                  }
              }
            `,
            variables: {
              id: this.module,
            },
            fetchPolicy: 'no-cache',
          });
          if (module && module.data && module.data.modules && module.data.modules[0]) {
            let code = module.data.modules[0].code
            let greaterMs = 0
            let lesserMs = null
            let greaterFDN = 0
            let lesserFDN = null
            let greaterPB = 0
            let lesserPB = null
            let greaterEM = 0
            let lesserEM = null
            const layers = this.allLayers
            let layersArray = []
            for (let index = 0; index < layers.length; index++ ) {
              let layer = layers[index]
              if (layer.properties.Name.startsWith(code)) {
                layersArray.push(layer)
                if (greaterMs < parseFloat(layer.properties.ms)) {
                  greaterMs = layer.properties.ms
                } 
                if (lesserMs === null || lesserMs > parseFloat(layer.properties.ms)) {
                  lesserMs = layer.properties.ms
                }
                if (greaterFDN < parseFloat(layer.properties.fdn)) {
                  greaterFDN = layer.properties.fdn
                } 
                if (lesserFDN === null || lesserFDN > parseFloat(layer.properties.fdn)) {
                  lesserFDN = layer.properties.fdn
                }
                if (greaterPB < parseFloat(layer.properties.pb)) {
                  greaterPB = layer.properties.pb
                } 
                if (lesserPB === null || lesserPB > parseFloat(layer.properties.pb)) {
                  lesserPB = layer.properties.pb
                }
                if (greaterEM < parseFloat(layer.properties.em)) {
                  greaterEM = layer.properties.em
                } 
                if (lesserEM === null || lesserEM > parseFloat(layer.properties.em)) {
                  lesserEM = layer.properties.em
                }
              }
            }
            this.rangeMS[0] = greaterMs
            this.rangeMS[1] = lesserMs
            this.rangeFDN[0] = greaterFDN
            this.rangeFDN[1] = lesserFDN
            this.rangePB[0] = greaterPB
            this.rangePB[1] = lesserPB
            this.rangeEM[0] = greaterEM
            this.rangeEM[1] = lesserEM
            this.layers.features = layersArray
          }
        } else {
          this.layers.features = this.allLayers
        }
      },

      async alterLabelVisibility () {
        const mapa = this.mapa
        if (this.filter.label) {
          mapa.eachLayer(function(l) {
            if (l.getTooltip()) {
              var tooltip = l.getTooltip();
              l.unbindTooltip().bindTooltip(tooltip, {
                  permanent: true
              })
            }
          })
        } else {
          mapa.eachLayer(function(l) {
            if (l.getTooltip()) {
              var tooltip = l.getTooltip();
              l.unbindTooltip().bindTooltip(tooltip, {
                  permanent: false
              })
            }
          })
        }
      },

      async print () {
        const el = this.$refs.mapa;
        const options = {
          type: 'dataURL',
          useCORS: true,  
        }
        const printCanvas = await this.$html2canvas(el, options);
        const link = document.createElement("a");
        link.href = printCanvas
        link.download = 'image.png'
        link.click()
      }
    }
  }
</script>

<template>
  <div>
    <filter-map :class="$vuetify.breakpoint.xs? 'no-print' : $vuetify.breakpoint.sm? 'no-print' : 'filter no-print'"/>
    <div id="mapa" ref="mapa">
      <v-col cols="12" sm="12" align="right" data-html2canvas-ignore="true">
        <v-btn class="button-map" @click="print">Salvar</v-btn>
      </v-col>
    </div>
  </div>
</template>

<style>
  #mapa {
    height: 85vh;
    width: 100%;
    z-index: 1;
  }
  .button-map {
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none !important; 
    z-index: 1001;
  }
  .v-application .info {
    background-color: white !important;
    border-color: white !important;
  }
  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  .info h4 {
      margin: 0 0 5px;
      color: #777;
  }
  .legend {
    line-height: 18px;
    color: #555;
    background: white;
}
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 1;
}
.tooltip-dark {
  font-size: 8px;
  background-color: rgba(0,0,0,0) !important;
  border:none;
  box-shadow: none;
}
.tooltip-light {
  font-size: 8px;
  color: white;
  background-color: rgba(0,0,0,0) !important;
  border:none;
  box-shadow: none;
}
.leaflet-control-layers {
  visibility: collapse;
}
</style>